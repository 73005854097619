// src/components/LandingPage.js
import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login');
  };

  return (
    <Container maxWidth={false} sx={{ px: 4 }}>
      {/* Hero Section */}
      <Box sx={{
            backgroundColor: '#121212',  // Dark background color
            color: 'white',
            py: 9,
            textAlign: 'center'
            }}>
        <Typography variant="h2" gutterBottom>Welcome to Debugger</Typography>
        <Typography variant="h5" gutterBottom>
          The ultimate tool for managing and analyzing logs in real-time.
        </Typography>
        <Button variant="contained" color="primary" size="large" onClick={handleLoginClick} sx={{ mt: 4 }}>
          Get Started
        </Button>
      </Box>

      {/* Features Section */}
      <Box sx={{ py: 8 }}>
        <Typography variant="h4" align="center" gutterBottom>Key Features</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
          {[
            { title: "Real-time Monitoring", description: "See log updates instantly as they occur." },
            { title: "Search & Filter", description: "Quickly find specific messages with search filters." },
            { title: "User Management", description: "Manage user access and permissions seamlessly." },
            { title: "Bulk Erase", description: "Easily clear logs with one click." },
          ].map((feature, index) => (
            <Box key={index} sx={{ width: '250px', m: 2, textAlign: 'center' }}>
              <Typography variant="h6">{feature.title}</Typography>
              <Typography variant="body2" color="textSecondary">{feature.description}</Typography>
            </Box>
          ))}
        </Box>
      </Box>

      {/* How It Works Section */}
      <Box sx={{ py: 8, backgroundColor: '#f9f9f9' }}>
        <Typography variant="h4" align="center" gutterBottom>How It Works</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4, flexWrap: 'wrap' }}>
          {["Sign In", "Monitor Logs", "Search & Filter", "Erase & Manage"].map((step, index) => (
            <Box key={index} sx={{ width: '200px', textAlign: 'center', p: 2 }}>
              <Typography variant="h6">Step {index + 1}</Typography>
              <Typography variant="body2">{step}</Typography>
            </Box>
          ))}
        </Box>
      </Box>

      {/* Footer Section */}
      <Box sx={{ backgroundColor: '#333', color: 'white', py: 2, textAlign: 'center' }}>
        <Typography variant="body2">© 2024 Debugger. All rights reserved.</Typography>
        <Typography variant="body2">Developed by CT Developers.</Typography>
      </Box>
    </Container>
  );
};

export default LandingPage;
